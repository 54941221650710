import './VideoStream.css'

import { Sym } from '@edclass/fe-ui'
import { StageStream } from 'amazon-ivs-web-broadcast'
import clsx from 'clsx'
import { ReactNode, useEffect, useRef, useState } from 'react'

import { EDIcon } from '@/constants/constants.ts'

function StreamImpl({
  //stageStreams,
  autoPlay = true,
  className,
  wrapperClassName,
  controls,
  id,
  stream,
  forceUnmute,
}: {
  forceUnmute?: boolean
  id?: string
  autoPlay?: boolean
  //stageStreams?: StageStream[]
  stream?: MediaStream
  wrapperClassName?: string
  className?: string
  controls?: boolean
}) {
  // required to be muted by default or there will be browser error policy if autoplay is on
  const [muted, setMuted] = useState(!forceUnmute)
  const videoRef = useRef<HTMLMediaElement | null>(null)
  useEffect(() => {
    console.log('video ref current', videoRef.current)
  }, [videoRef])

  /*
  useEffect(() => {
    console.log('video ref stageStreams', stageStreams)
  }, [stageStreams])*/

  useEffect(() => {
    console.log(
      'video ref stream',
      stream?.getTracks(),
      stream?.getVideoTracks(),
    )
  }, [stream])

  useEffect(() => {
    if (videoRef.current && stream && autoPlay) {
      // Set the srcObject and make sure autoplay works
      videoRef.current.srcObject = stream
      videoRef.current.play().catch((err) => {
        console.error('Autoplay failed:', err)
      })
    }
  }, [stream, autoPlay]) // This will re-run when mediaStream changes

  return (
    <div className={clsx('video-stream-wrapper relative', wrapperClassName)}>
      <button
        onClick={() => setMuted(!muted)}
        className="absolute z-[10] bottom-1 right-1 rounded bg-black/30 p-1"
      >
        <Sym className="!text-[20px]">{muted ? 'volume_off' : 'volume_up'}</Sym>
      </button>
      <video
        /*ref={(ref) => {
          // Function to set the video stream
          if (ref && stream) {
            videoRef.current = ref
            ref.srcObject = stream
          } else if (ref && stageStreams) {
            videoRef.current = ref
            const mediaStream = new MediaStream() // Initialize a new MediaStream
            // Add tracks from the streamsToDisplay to the srcObject of the video
            stageStreams.forEach?.((stream) =>
              mediaStream.addTrack(stream.mediaStreamTrack),
            )
            ref.srcObject = mediaStream
          } else {
            videoRef.current = ref
          }
        }}*/
        ref={videoRef as never}
        id={id}
        muted={muted}
        className={className}
        autoPlay={autoPlay}
        controls={controls}
      />
    </div>
  )
}
export default function VideoStream({
  stageStreams,
  size = 'xs',
  className,
  placeholder,
  placeholderIcon,
  stream,
  ...props
}: {
  id?: string
  autoPlay?: boolean
  stageStreams?: StageStream[]
  stream?: MediaStream
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'auto'
  className?: string
  wrapperClassName?: string
  placeholder?: ReactNode
  placeholderIcon?: string
  controls?: boolean
  forceUnmute?: boolean
}) {
  const mediaClassName = clsx('video-stream', size, className)

  if (!stageStreams && !stream) {
    return placeholder ? (
      placeholder
    ) : (
      <div className={clsx(mediaClassName, 'cc placeholder')}>
        <Sym className="!text-[40px]">{placeholderIcon || EDIcon.EDLive}</Sym>
      </div>
    )
  }

  return (
    <StreamImpl
      {...props}
      //stageStreams={stageStreams}
      stream={stream}
      className={mediaClassName}
    />
  )
}
