import { Button, Input } from '@material-tailwind/react'
import { useState } from 'react'

import FileManagerInput from '@/components/FileManager/FileManagerInput.tsx'
import NumInput from '@/components/Input/NumInput.tsx'
import SymbolNav from '@/components/SymbolNav'
import { EDIcon } from '@/constants/constants.ts'
import { getPath, PathKind } from '@/helpers/path.ts'
import { hasRole } from '@/helpers/user.ts'
import useAppContext from '@/hooks/useAppProvider.ts'
import useAuthContext from '@/hooks/useAuthProvider.ts'
import ParentHomePage from '@/pages/HomePage/ParentHomePage.tsx'
import StudentHomePage from '@/pages/HomePage/StudentHomePage.tsx'
import { getApiService } from '@/services/api.ts'
import { getFsService } from '@/services/fs.ts'

export default function HomePage() {
  const { user } = useAuthContext()
  const { headerTitle, sidebar } = useAppContext()
  //const [file, setFile] = useState<File | null>(null)
  const [uploadUrl, setUploadUrl] = useState(
    'http://localhost:8002/file/upload',
  )

  //const videoRef = useRef<HTMLVideoElement>(null)
  /*useEffect(() => {
    const { current } = videoRef
    if (current) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            width: { min: 1024, ideal: 1280, max: 1920 },
            height: { min: 576, ideal: 720, max: 1080 },
            facingMode: 'user',
          },
          audio: true,
          preferCurrentTab: true,
        })
        .then((mediaStream) => {
          current.srcObject = mediaStream
          current.onloadedmetadata = () => {
            current.play()
          }
        })
    }
  }, [])*/

  const [createStud, setCreateStud] = useState<{
    schoolId: string
    startIdx: string
    endIdx: string
  }>({
    schoolId: '',
    startIdx: '1',
    endIdx: '10',
  })

  if (!user) {
    return null
  }

  if (hasRole(user, 'ROLE_STUDENT')) {
    return <StudentHomePage />
  } else if (hasRole(user, 'ROLE_PARENT')) {
    return <ParentHomePage />
  } /* else if (hasRoles(user, ['ROLE_EDCLASS_STAFF'])) {
    return <Navigate to={`${BASE_PATH}/${TEACHER_PATH}`} replace />
  } else if (hasRoles(user, ['ROLE_EDCLASS_STAFF'])) {
    return <Navigate to={`${BASE_PATH}/${ADMIN_PATH}`} replace />
  }*/

  return (
    <div className="flex flex-col gap-4 p-4">
      <headerTitle.In>
        <div>Home</div>
      </headerTitle.In>
      <sidebar.In>
        <SymbolNav icon={EDIcon.Safeguarding} to={getPath(PathKind.Admin, '')}>
          Admin
        </SymbolNav>
        <SymbolNav icon={EDIcon.Education} to={getPath(PathKind.Teacher, '')}>
          Teacher
        </SymbolNav>
        {/*
           <SymbolNav icon={EDIcon.StudentLive} to={getPath(PathKind.Student, '')}>
          Student
        </SymbolNav>
           */}
        <SymbolNav
          icon={EDIcon.Safeguarding}
          to={getPath(PathKind.Safeguarding, '')}
        >
          Safeguarding
        </SymbolNav>
      </sidebar.In>
      {/*
        <div>
        <input
          type="file"
          name="file"
          onChange={(e) => {
            const f = e.currentTarget.files?.[0]
            if (f) {
              setFile(f)
            }
          }}
        />
        <Button
          
          onClick={() => {
            if (file) {
              const formData = new FormData()
              formData.append('file', file)
              formData.append('file', file)
              axios
                .post('http://localhost:5000/uploads', formData)
                .then(console.log)
                .catch(console.error)
            } else {
              alert('no file selected')
            }
          }}
        >
          Test Upload
        </Button>
      </div>
         */}
      {/*<video ref={videoRef} />*/}

      <div className="flex-c-2">
        <Input
          label="school ID"
          value={createStud.schoolId}
          onChange={(e) => {
            setCreateStud({
              ...createStud,
              schoolId: e.target.value,
            })
          }}
        />
        <NumInput
          label="Start Index"
          value={createStud.startIdx}
          onChange={(e) => {
            setCreateStud({
              ...createStud,
              startIdx: e.target.value,
            })
          }}
        />
        <NumInput
          label="End Index"
          value={createStud.endIdx}
          onChange={(e) => {
            setCreateStud({
              ...createStud,
              endIdx: e.target.value,
            })
          }}
        />
        <Button
          className="flex-shrink-0"
          onClick={async () => {
            const sIdx = Number(createStud.startIdx)
            const eIdx = Number(createStud.endIdx)

            await Promise.all(
              Array.from(Array(eIdx - sIdx).keys()).map(async (a) => {
                return await getApiService().addSchoolStudent(
                  createStud.schoolId,
                  {
                    firstName: `stud${sIdx + a}`,
                    lastName: '',
                    gender: 'male',
                    birthDate: '1990-10-01',
                    email: `stud${sIdx + a}@gmail.com`,
                    permissions: [],
                    roles: ['ROLE_STUDENT'],
                    picture: '',
                    username: `stud${sIdx + a}`,
                    pronoun: 'he',
                    yearGroup: 1,
                    difficulty: 1,
                  },
                )
              }),
            )
          }}
        >
          Create students
        </Button>
      </div>
      <div>
        <Input
          label="Enter upload url with POST method"
          value={uploadUrl}
          onChange={(e) => {
            setUploadUrl(e.target.value)
          }}
        />
      </div>
      <div>
        <div>Hit open to test upload</div>
        <div>
          upload is multipart form data with{' '}
          <code className="text-blue-500">files</code> as field name
        </div>
        <FileManagerInput
          selectedFiles={[]}
          multiple
          onSelect={(f) => {
            console.log(f)
          }}
          onUpload={async (files) => {
            return getFsService().upload(files, {
              folder: 'herman/',
            })
          }}
        />
      </div>
    </div>
  )
}
