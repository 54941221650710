// eslint-disable-next-line react-refresh/only-export-components
import { Sym } from '@edclass/fe-ui'
import clsx from 'clsx'
import {
  ComponentProps,
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'

import Avatar from '@/components/Avatar'
import VideoStream from '@/components/Video/VideoStream.tsx'
import { getUserDisplayName } from '@/helpers/user.ts'
import useContextOrThrow from '@/hooks/useContextOrThrow.ts'

type VideoDialogContextValue = {
  streams: Record<
    string,
    {
      display?: MediaStream
      user?: MediaStream
      info: SgParticipantAttributes
    }
  >
  setStreams: SetState<
    Record<
      string,
      {
        display?: MediaStream
        user?: MediaStream
        info: SgParticipantAttributes
      }
    >
  >
  addStream: (
    userId: string,
    kind: 'display' | 'user',
    stream: MediaStream,
  ) => void
  removeStream: (userId: string, kind: 'display' | 'user' | 'all') => void
  open: boolean
  setOpen: SetState<boolean>
}

const VideoDialogContext = createContext<VideoDialogContextValue | null>(null)
VideoDialogContext.displayName = 'VideoDialogContext'

// eslint-disable-next-line react-refresh/only-export-components
export function useVideoDialog() {
  return useContextOrThrow(VideoDialogContext)
}

function VideoPanel({
  userId,
  display,
  user,
}: {
  userId: string
  info: SgParticipantAttributes
  display?: MediaStream
  user?: MediaStream
}) {
  const gridClass = useMemo(() => {
    if (Boolean(display) && Boolean(user)) {
      return 'grid-cols-2'
    } else {
      return 'grid-cols-1'
    }
  }, [display, user])

  const userOk = Boolean(user)
  const displayOk = Boolean(display)

  return (
    <div className={clsx('grid', gridClass)}>
      {user && (
        <VideoStream
          id={userId}
          placeholder={
            <div className="cc bg-indigo-500">
              <Avatar
                size={64}
                className="shadow"
                user={{
                  id: userId,
                }}
              />
            </div>
          }
          className={clsx(userOk ? '!bg-ed-purple' : '!bg-indigo-500')}
          autoPlay
          stream={user}
        />
      )}
      {display && (
        <VideoStream
          id={userId}
          className={clsx(displayOk ? '!bg-ed-blue' : '!bg-blue-gray-900')}
          autoPlay
          stream={display}
        />
      )}
    </div>
  )
}

function StreamPanel({
  user,
  display,
  userId,
  info,
}: ComponentProps<typeof VideoPanel>) {
  return (
    <div>
      <VideoPanel info={info} userId={userId} display={display} user={user} />
      <div className="bg-black/30 text-white text-body p-2">
        {getUserDisplayName(info as unknown as User)}
      </div>
    </div>
  )
}

export function VideoDialogPanel() {
  const { streams, setOpen } = useVideoDialog()

  const streamsArr = useMemo(() => {
    return Object.entries(streams)

    /*return Array.from(Array(10).keys()).map((i) => {
      return [
        i.toString(),
        {
          user: new MediaStream(),
          display: new MediaStream(),
          info: {
            email: 'asd@gmail.com',
            firstName: 'name',
            lastName: 'last',
            picture: undefined,
          },
        },
      ]
    })*/
  }, [streams])

  return (
    <div className="relative rounded-md bg-gray-100 text-ed-text h-full w-full flex flex-col gap-4 overflow-hidden">
      <div className="flex justify-end pe-2 h-8">
        <button onClick={() => setOpen(false)}>
          <Sym className="!text-[20px]">close</Sym>
        </button>
      </div>
      <div className="h-full overflow-y-auto">
        {streamsArr.length > 0
          ? streamsArr.map(([userId, { display, user, info }]) => {
              return (
                <StreamPanel
                  userId={userId}
                  key={userId}
                  info={info}
                  display={display}
                  user={user}
                />
              )
            })
          : null}
      </div>
    </div>
  )
}

export default function VideoDialog({ children }: { children?: ReactNode }) {
  const [streams, setStreams] = useState<
    Record<
      string,
      {
        display?: MediaStream
        user?: MediaStream
        info: SgParticipantAttributes
      }
    >
  >({})

  const addStream = useCallback(
    (userId: string, kind: 'display' | 'user', stream: MediaStream) => {
      setStreams((prev) => {
        if (!prev[userId]?.[kind]) {
          const copy = { ...prev }
          copy[userId] = {
            ...(copy[userId] || {}),
            [kind]: stream,
          }
          return copy
        }
        return prev
      })
    },
    [],
  )

  const removeStream = useCallback(
    (userId: string, kind: 'display' | 'user' | 'all') => {
      setStreams((prev) => {
        if (kind === 'all') {
          if (prev[userId]) {
            delete prev[userId]
          }
          return prev
        } else {
          if (prev[userId]?.[kind]) {
            const copy = { ...prev }
            copy[userId] = {
              ...(copy[userId] || {}),
              [kind]: undefined,
            }
            delete copy[userId][kind]
            return copy
          }
        }

        return prev
      })
    },
    [],
  )

  const [open, setOpen] = useState(false)

  return (
    <VideoDialogContext.Provider
      value={{
        streams,
        setStreams,
        addStream,
        removeStream,
        open,
        setOpen,
      }}
    >
      {children}
    </VideoDialogContext.Provider>
  )
}
