import './HeaderMenu.css'

import { DraggableDialogSet, Sym } from '@edclass/fe-ui'
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Spinner,
} from '@material-tailwind/react'
import clsx from 'clsx'
import { useMemo, useState } from 'react'

import LoggedNavLink from '@/components/Link/LoggedNavLink.tsx'
import StackedAvatar from '@/components/Stacked/StackedAvatar.tsx'
import { EDIcon, MY_ACCOUNT_PATH } from '@/constants/constants.ts'
import { PathKind } from '@/helpers/path.ts'
import { usePathNames } from '@/helpers/route.ts'
import { getUserDisplayName } from '@/helpers/user.ts'
import useTutorial from '@/hooks/query/useTutorial.ts'
import useAppContext from '@/hooks/useAppProvider.ts'
import useAuthContext from '@/hooks/useAuthProvider.ts'
import useLogout from '@/hooks/useLogout.tsx'
import {
  NotificationProvider,
  useNotification,
} from '@/providers/NotificationProvider'
import { getFsUrl } from '@/services/fs.ts'

function NotificationMenu() {
  const { notifications, unreadNotifications, markRead } = useNotification()
  return (
    <Menu placement="bottom-start">
      <MenuHandler>
        <button className="app-menu-notif cc relative w-[42px] h-[42px] hover:bg-blue-500 rounded-full transition-colors">
          {unreadNotifications > 0 && (
            <span className="absolute cc z-[3] top-[-0.1rem] min-w-[20px] right-[-0.25rem] bg-red-500 font-bold font-mono text-body py-0.25 px-1 rounded-full">
              {unreadNotifications}
            </span>
          )}
          <Sym className="!text-[32px]">{EDIcon.Notification}</Sym>
        </button>
      </MenuHandler>
      <MenuList className="w-[300px] max-h-[300px] overflow-y-auto">
        {notifications.length > 0 ? (
          notifications.map((n, i) => {
            return (
              <MenuItem
                className={clsx(
                  n.isRead && '!text-blue-gray-500 hover:bg-black/30',
                  'text-body',
                )}
                key={`${n.id}-${i}`}
                onClick={() => {
                  markRead(n)
                }}
              >
                {n.message}
              </MenuItem>
            )
          })
        ) : (
          <div className="p-2">No notifications found</div>
        )}
      </MenuList>
    </Menu>
  )
}

function HelpMenuImpl({ path }: { path: string }) {
  const { data, isPending } = useTutorial(path)
  return isPending ? (
    <Spinner />
  ) : data ? (
    <div className="flex flex-col gap-4">
      <div>{data.text}</div>
      {data.videoUrl ? (
        <video className="w-full" controls src={getFsUrl(data.videoUrl)} />
      ) : null}
    </div>
  ) : (
    <div>Data not found</div>
  )
}

function HelpMenuWrapper() {
  const paths = usePathNames()
  const path = useMemo(() => {
    if (Object.values(PathKind).includes(paths[0] as PathKind)) {
      return paths[1]
    }
    return paths[0]
  }, [paths])

  return (
    <div>
      {/*
        <pre>{JSON.stringify(paths, null, 2)}</pre>
         */}
      <HelpMenuImpl path={path} />
    </div>
  )
}

function HelpMenu() {
  const [open, setOpen] = useState(false)
  return (
    <>
      <button
        title="help"
        onClick={() => setOpen(true)}
        className="app-menu-help cc relative w-[42px] h-[42px] hover:bg-blue-500 rounded-full transition-colors"
      >
        <Sym className="!text-[32px]">help</Sym>
      </button>
      {open && (
        <DraggableDialogSet
          open={open}
          handler={() => setOpen(false)}
          backdrop
          className="bg-ed-bg max-h-[70vh] overflow-y-auto"
          bodyProps={{
            divider: true,
          }}
          header="Help"
        >
          <HelpMenuWrapper />
        </DraggableDialogSet>
      )}
    </>
  )
}

export default function HeaderMenu() {
  const { user } = useAuthContext()
  const { getEventWebSocket, getSgWebSocket } = useAppContext()
  const logout = useLogout(() => {
    getSgWebSocket()?.close(1000, 'logout')
    getEventWebSocket()?.close(1000, 'logout')
  })

  return (
    <div className="flex-c gap-4">
      <NotificationProvider>
        <NotificationMenu />
      </NotificationProvider>
      <HelpMenu />
      {user && (
        <Menu>
          <MenuHandler>
            <button className="cc app-menu-user">
              <StackedAvatar
                noBorder
                fullSize
                size="42px"
                color="text-white/70 hover:bg-blue-500 hover:text-white "
                src={user?.picture}
              />
            </button>
          </MenuHandler>
          <MenuList>
            <div>Hello, {getUserDisplayName(user)}</div>
            <MenuItem aria-label="Profile">
              <LoggedNavLink
                className="flex-c-2"
                to={`${MY_ACCOUNT_PATH}/profile`}
              >
                <Sym>account_box</Sym>
                <span>Profile</span>
              </LoggedNavLink>
            </MenuItem>
            <MenuItem
              className="!flex-c-2"
              onClick={() => alert('coming soon')}
              aria-label="Inbox"
            >
              <Sym>mail</Sym>
              <span>Inbox</span>
            </MenuItem>
            <MenuItem
              className="!flex-c-2"
              onClick={() => {
                const ws = getEventWebSocket()
                if (ws?.readyState === 1) {
                  console.log('close ws connection')
                  ws?.close()
                }
                logout().catch(console.error)
              }}
              aria-label="Logout"
            >
              <Sym>logout</Sym>
              <span>Logout</span>
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </div>
  )
}
