import { ThemeProvider } from '@material-tailwind/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { lazy } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import {
  BASE_PATH,
  ERROR_NOT_FOUND_PATH,
  MY_ACCOUNT_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
} from '@/constants/constants.ts'
import { ED_THEME } from '@/constants/theme.ts'
import { PathKind } from '@/helpers/path.ts'
import adminRoutes from '@/pages/AdminPage/route'
import ErrorNotFound from '@/pages/ErrorPage/ErrorNotFound'
import HomePage from '@/pages/HomePage'
import LoginPage from '@/pages/LoginPage'
import StudentProfile from '@/pages/ProfilePage/StudentProfile.tsx'
import ProtectedRoute from '@/pages/ProtectedRoute.tsx'
import safeguardingRoutes from '@/pages/SafeguardingPage/route'
import studentRoutes from '@/pages/StudentPage/route.tsx'
import teacherRoute from '@/pages/TeacherPage/route'
import AuthProvider from '@/providers/AuthProvider.tsx'
import GlobalProvider from '@/providers/GlobalProvider'
import UploadProvider from '@/providers/UploadProvider'

import { queryClient } from './helpers/global'

const Profile = lazy(() => {
  return import('@/pages/ProfilePage/index.tsx')
})

const router = createBrowserRouter([
  {
    path: BASE_PATH,
    element: <ProtectedRoute />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      teacherRoute,
      studentRoutes,
      adminRoutes,
      safeguardingRoutes,
      {
        path: `${MY_ACCOUNT_PATH}/profile`,
        element: <Profile />,
      },
      {
        path: `${PathKind.Student}/:id/profile`,
        element: <StudentProfile />,
      },
    ],
  },
  {
    path: `${BASE_PATH}/${ERROR_NOT_FOUND_PATH}`,
    element: <ErrorNotFound />,
  },
  {
    path: `${BASE_PATH}/${SIGN_IN_PATH}`,
    element: <LoginPage />,
  },
  {
    path: `${BASE_PATH}/${SIGN_UP_PATH}`,
    element: <LoginPage />,
  },
  {
    path: `${BASE_PATH}/*`,
    element: <ErrorNotFound />,
  },
])

export default function Root() {
  return (
    <GlobalProvider>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider value={ED_THEME}>
            <UploadProvider>
              <RouterProvider router={router} />
            </UploadProvider>
          </ThemeProvider>
          <ReactQueryDevtools position="bottom" buttonPosition="top-left" />
        </QueryClientProvider>
      </AuthProvider>
    </GlobalProvider>
  )
}
